/*--------------------------------------
/*  Brand Css Start
----------------------------------------*/

.single-brand {
    text-align: center;
    .thumb {
        display: block;
    }
}
// .hover-effect {
//     filter: grayscale(1);
// }
// .hover-effect:hover {
//     filter: grayscale(0);
// }
/*--------------------------------------
/*  Brand Css ENd
----------------------------------------*/
