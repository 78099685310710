@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Yantramanav:wght@100;300;400;500;700;900&display=swap");

// font-family: 'Open Sans', sans-serif;
// font-family: 'Yantramanav', sans-serif;

/*------------------------ 
/* Common Style
 ------------------------*/
/*-----------------------
# selection colors
-------------------------*/

*::-moz-selection {
  background: $primary;
  color: $white;
  text-shadow: none;
}

::selection {
  background: $primary;
  color: $white;
  text-shadow: none;
}

button {
  background: transparent;
  border: 0;
  padding: 0;
  outline: 0;
}

a {
  text-decoration: none;
  &:focus {
    text-decoration: none;
    outline: 0;
  }
  &:hover {
    text-decoration: none;
    color: #368a76;
  }
}

img {
  max-width: 100%;
  transition: all linear 0.5s;
}
.size-icon{
  padding: 0 26px;
  font-size: 25px;
}
.btn-hover-dark-andr-e:hover {
  border-color: #263238;
  background-color: #263238;
  color: #fff;
}
.btn-hover-dark-app-e:hover {
  border-color: #263238;
  background-color: #263238;
  color: #fff;
  background: #44ac94;
  border-color: #44ac94;
}
.btn-dark-app-e{
  border-color: #263238;
  background-color: #263238;
  color: #fff;
}
.btn-dark-andr-e{  
  border-color: #263238;
  background-color: #263238;
  color: #fff;
  background: #44ac94;
  border-color: #44ac94;
}
.es-sty{
  cursor : pointer;
}
.es-sty img{
  width: 170px;
}
.bckimg-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -682px;
  margin-bottom: 0;
  @include max-screen(1200) {
    display: none;
  }
}
.bckimg-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -746px;
  margin-bottom: 0;
  @include max-screen(1200) {
    display: none;
  }
}
.bckimg-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -700px;
  margin-bottom: 0;
  @include max-screen(1200) {
    display: none;
  }
}
.bckimg-4 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -697px;
  margin-bottom: 0;
  @include max-screen(1200) {
    display: none;
  }
}
.bckimg-5 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -663px;
  margin-bottom: 0;
  @include max-screen(1200) {
    display: none;
  }
}
.del-img-767 {
  @include max-screen(767) {
    display: none !important;
  }
}
.my-e-5{
  margin: 90px 0;
}
.mar-top-es{
  margin: 63px 0;
}
.img-es{
  width: 21%;
  @include min-screen(1500) {
    width: 19%;
  }
  @include max-screen(992, 1199) {
    width: 19%;
  }
  @include max-screen(767) {
    width: 19%;
  }
  @include max-screen(480) {
    width: 4rem;
  }
  @include max-screen(576, 767) {
    width: 22%;
  }
}
.img-es-foot {
  width: 35%;
}

:focus {
  outline: 0;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a,
button {
  transition: $transition-base;
}

p {
  &:last-child {
    margin-bottom: 0;
  }
}

.high-light {
  color: #636363;
  font-family: "Metropolis Semi Bold";
  font-size: 18px;

  @include max-screen(991) {
    font-size: 16px;
  }
}

.ml-n2 {
  @include min-screen(992) {
    margin-left: -20px;
  }
}

.mr-n2 {
  @include min-screen(992) {
    margin-right: -20px;
  }
}
.fw-semi-bold {
  font-weight: 600;
}

.font-size18 {
  font-size: 18px;
}

// section-padding

.section-py {
  padding: 70px 0px;
  @include min-screen(1200) {
    padding: 140px 0px;
  }
}

.section-pt {
  padding-top: 70px;
  @include min-screen(1200) {
    padding-top: 140px;
  }
}
.section-pb {
  padding-bottom: 70px;
  @include min-screen(1200) {
    padding-bottom: 140px;
  }
}

// section-margin

.section-mt {
  margin-top: 70px;
  @include min-screen(1200) {
    margin-top: 140px;
  }
}

// pagination-hr

.pagination-hr {
  display: inline-block;
  height: 1px;
  background: $primary;
  width: 60px;
  position: relative;
  top: -6px;
}

.swiper-button-disabled {
  display: none;
}

label {
  margin-bottom: 10px;
}
.success {
  color: $primary;
}

// .valid{
//   color: $primary;
// }

.error {
  color: red;
}

br {
  @include max-screen(768, 991) {
    display: none;
  }
}

.copyright-info {
  i {
    color: red;
  }
}
/*-------- Scroll To Top Style Css --------*/

.scroll-to-top {
  position: fixed;
  right: 30px;
  bottom: -60px;
  z-index: 999;
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.03);
  display: block;
  padding: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  font-size: 25px;
  line-height: 50px;
  border: 0;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  background-color: $primary;
  background-size: 200% auto;
  background-position: left center;
  color: $white;
  transition: all 0.3s ease-in-out;
  overflow: hidden;

  &.show {
      visibility: visible;
      opacity: 1;
      bottom: 60px;
  }

  i {
      position: absolute;
      top: 50%;
      left: 50%;
      color: $white;
      transition: all 0.3s ease-in-out;
  }

  .arrow-top {
      transform: translate(-50%, -50%);
  }

  .arrow-bottom {
      transform: translate(-50%, 80px);
  }

  &:hover {
      background-position: right center;
      .arrow-top {
          transform: translate(-50%, -80px);
      }
      .arrow-bottom {
          transform: translate(-50%, -50%);
      }
  }
}

