@keyframes showTopText {
    0% {
        transform: translate3d(0, 100%, 0);
    }
    40%,
    60% {
        transform: translate3d(0, 50%, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}
@keyframes showBottomText {
    0% {
        transform: translate3d(0, -100%, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}
.animated-title {
    height: 47vh;
    left: 6%;
    position: relative;
    top: 16%;
    transform: translate(-4%, -23%);
    width: 100vh;
}
.animated-title > div {
    height: 50%;
    overflow: hidden;
    position: absolute;
    width: 100%;
}
.animated-title > div div {
    font-size: 10vmin;
    padding: 2vmin 0;
    position: absolute;
}
.animated-title > div div span {
    display: block;
}
.animated-title > div.text-top {
    border-bottom: 1vmin solid #000;
    top: 0;
}
.animated-title > div.text-top div {
    animation: showTopText 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    bottom: 0;
    transform: translate(0, 100%);
}
.animated-title > div.text-top div span:first-child {
    color: #767676;
}
.animated-title > div.text-bottom {
    bottom: 0;
}
.animated-title > div.text-bottom div {
    animation: showBottomText 0.5s;
    animation-delay: 1.75s;
    animation-fill-mode: forwards;
    top: 0;
    transform: translate(0, -100%);
    display: flex;
}

// .container {
//     width: 100vw;
//     height: 100vh;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
//   .shadows {
//     position: absolute;
//     text-transform: uppercase;
//     text-shadow: -15px 5px 20px #ced0d3;
//     color: white;
//     letter-spacing: -0.05em;
//     font-family: "Anton", Arial, sans-serif;
//     -webkit-user-select: none;
//     user-select: none;
//     text-transform: uppercase;
//     font-size: 150px;
//     top: 0;
//     right: 0;
//     transition: all 0.25s ease-out;
//   }
//   .shadows:hover {
//       text-shadow: -16px 6px 15px #ced0d3;
//   }
